.browse-map.leaflet-container {
  width: 100%;
}
.mapHolder {
  height: 80%;
  width: 100%;
  position: relative;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
