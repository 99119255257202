.food-badge {
    color: black;
    background-color:rgba(176, 0, 32, 0.24);
}

.feed-badge {
    color: black;
    background-color: rgba(187, 134, 252, 0.38);
}

.cult-badge {
    color: black;
    background-color: rgba(250, 252, 134, 0.6);
    display: block;
}