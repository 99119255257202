.offcanvas-title {
    color: #9F1010;
    font-family: 'Roboto';
    font-size: 2em;
    font-weight: bold;
}

.statistic {
    color: #018786;
    font-family: 'Roboto';
    font-size: 1.75em;
    font-weight: bold;
}

.navButton {
    background-color: #9F1010; 
    font-weight: bold;
}

.navButton:hover{
    background-color: #9F1010;
}