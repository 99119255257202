.mBioNavbar {
    background-color: #018786;
    font-family: "Roboto Condensed";
}

img {
    height: 1.5em;
    width: 1.5em;
}

.navbar-light .navbar-brand {
    font-size: 1.5em;
    color: #FFFFFF;
}

.navbar-light .navbar-nav .nav-link {
    color: #FFFFFF;
    font-weight: lighter;
}
.navbar-light .navbar-nav .nav-link:hover {
    color: #FAFC86;
}