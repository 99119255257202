.infoParent {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.modal-20w {
  position: absolute;
  bottom:80px;
  left:20px;
}
