/* Adapted from https://paladini.dev/posts/how-to-make-an-extremely-reusable-tooltip-component-with-react--and-nothing-else/ */

:root {
    --tooltip-text-color: white;
    --tooltip-background-color: black;
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
  }

/* Wrapping */
.Tooltip-Wrapper {
    display: inline-block;
    position: relative;
  }
  
  /* Absolute positioning */
  .Tooltip-Tip {
    position: absolute;
    width: 25em;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: wrap;
  }
  
  /* CSS border triangles */
  .Tooltip-Tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }