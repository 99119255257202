.report-container {
  margin-top: 1%;
} 
 
 .report-map.leaflet-container {
  height: 500px;
  width: 100%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.mapHolder {
  height: 80%;
  width: 100%;
  position: relative;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;;
}

.sectionHeader {
  font-family: 'Roboto';
  font-size: 1.25em;
  font-weight: normal;
  color: #9F1010;
  margin-bottom: -10px;
  padding-top: 1em;
}

.sectionSubHeader {
  font-family: 'Roboto';
  font-size: 1em;
  font-weight: normal;
}

.devChart {
    width: 100%;
    padding: 0;
    margin: 0;
}
