
.badge {
    border-radius: 50rem !important;
    background-color: #018786;
    font-size: 1em;
    font-weight: normal;
    margin: 0.12em;
    padding: 0.5em;
    white-space: normal;
    text-decoration: none;
    text-align: justify;
}
